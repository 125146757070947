import { useState } from "react";

interface ChangelogItem {
  icon: string;
  text: string;
}

interface ChangelogEntryProps {
  date: string;
  entries: ChangelogItem[];
}

const changelogEntries: { date: string; year: number; entries: ChangelogItem[] }[] = [
  { date: "Feb - 10", year: 2025, entries: [
    { icon: "/imgs/profile.svg", text: "add daily point % gain to stat card" },
    { icon: "/imgs/chart.svg", text: "add daily % gain to rewards/score in profile" },
  ]},
  { date: "Jan - 19", year: 2025, entries: [
    { icon: "/imgs/profile.svg", text: "add profile viewer" },
    { icon: "/imgs/chart.svg", text: "unlock monthly view for treasury chart" },
    { icon: "/imgs/ethereum.svg", text: "add prices in header to relevant views" },
  ]},
  { date: "Dec - 19", year: 2024, entries: [
    { icon: "/imgs/ethereum.svg", text: "add ETH gas price in stat bar" },
    { icon: "/imgs/chart.svg", text: "unlock weekly view for treasury chart" },
  ]},
  { date: "Nov - 29", year: 2024, entries: [
    { icon: "/imgs/passport.svg", text: "stats in pet passes view pet images" },
  ]},
  { date: "Nov - 27", year: 2024, entries: [
    { icon: "/imgs/paw.svg", text: "blend hack for pepe contract svgs" },
    { icon: "/imgs/paw.svg", text: "normalize enlarged contract svgs" },
    { icon: "/imgs/chart.svg", text: "chart axis fixes" },
    { icon: "/imgs/passport.svg", text: "pet passes view" },
  ]},
  { date: "Nov - 26", year: 2024, entries: [
    { icon: "/imgs/chart.svg", text: "treasury chart" },
    { icon: "/imgs/chart.svg", text: "fix certain chart tooltips" },
    { icon: "/imgs/chart.svg", text: "unlock weekly view for pgold balance chart" },
    { icon: "/imgs/rank.svg", text: "add species count to leaderboards" },
    { icon: "/imgs/rank.svg", text: "fix leaderboards intraweek sort" },
  ]},
  { date: "Nov - 22", year: 2024, entries: [
    { icon: "/imgs/gas.svg", text: "USD value per transaction" },
  ]},
  { date: "Nov - 19", year: 2024, entries: [
    { icon: "/imgs/chart.svg", text: "pgold balance in charts" },
    { icon: "/imgs/hibernated.svg", text: "hibernated badge" },
    { icon: "/imgs/passBadge.png", text: "moved non-player related charts to stats" },
    { icon: "/imgs/rank.svg", text: "leaderboards refresh daily, update weekly" },
    { icon: "/imgs/passBadge.png", text: "change pets alive data to update weekly" },
  ]},
  { date: "Nov - 15", year: 2024, entries: [
    { icon: "/imgs/paw.svg", text: "top 100 leaderboards for every species" },
    { icon: "/imgs/gear.svg", text: "removed attack and defense leaderboards" },
    { icon: "/imgs/frog.svg", text: "staked FP behind eth in shop/leaderboards" },
    { icon: "/imgs/compass.svg", text: "pet EP in shop/leaderboards" },
  ]},
  { date: "Nov - 12", year: 2024, entries: [
    { icon: "/imgs/star.svg", text: "top 100 keeper leaderboard added" },
    { icon: "/imgs/compass.svg", text: "available quests badge" },
  ]},
  { date: "Nov - 11", year: 2024, entries: [
    { icon: "/imgs/bolt.svg", text: "click bolt to see remaining bonk count" },
    { icon: "/imgs/passBadge.png", text: "pass badge indicating pet has FP Pass" },
    { icon: "/imgs/frog.svg", text: "frog badge indicating staking perks active" },
    { icon: "/imgs/coin-bag.svg", text: "pGold chart updated with monthly view" },
    { icon: "/imgs/ethereum.svg", text: "click ETH symbol on pet stat card to see FP" },
    { icon: "/imgs/compass.svg", text: "pet EP updated daily" },
    { icon: "/imgs/gear.svg", text: "sort by monsters, pass holder, EP added" },
    { icon: "/imgs/passBadge.png", text: "stats view added with pet species chart" },
    { icon: "/imgs/clipboard.svg", text: "change log view added" },
  ]},
];

export const Changelog = () => {
  const [show2024, setShow2024] = useState(false);

  return (
    <div className="flex flex-col items-center justify-left h-full w-full">
      {changelogEntries
        .filter(entry => entry.year === 2025)
        .map((entry, index) => (
          <ChangelogEntry key={index} date={entry.date} entries={entry.entries} />
        ))}

      <div className="btn-active [box-shadow:inset_0_-2px_#342e2e] mt-3 mb-1 p-2 rounded-md border-2 border-brown bg-white" onClick={() => setShow2024(!show2024)}>
        {show2024 ? "2024" : "2024"}
      </div>

      {show2024 && changelogEntries
        .filter(entry => entry.year === 2024)
        .map((entry, index) => (
          <ChangelogEntry key={index} date={entry.date} entries={entry.entries} />
        ))}
    </div>
  );
};

const ChangelogEntry: React.FC<ChangelogEntryProps> = ({ date, entries }) => (
  <div className="flex flex-col items-start justify-start mt-2 mb-3 rounded-lg border-2 border-brown bg-white [box-shadow:inset_0_-2px_#342e2e] w-[21.5rem]">
    <div className="flex flex-col items-start justify-center w-full">
      <h2 className="text-lg font-semibold mb-1 p-1 mt-1">{date}</h2>
      <hr className="w-full border-t-2 border-brown" />
      <ul className="w-full text-sm p-2">
        {entries.map((item, i) => (
          <li key={i} className="flex items-center mb-2">
            <img src={item.icon} alt="" className="h-4 w-4" />
            <div className="ml-[.2rem] mr-1">•</div>
            <p>{item.text}</p>
          </li>
        ))}
      </ul>
    </div>
  </div>
);
